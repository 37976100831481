var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "init"
  }, [_c("p", {
    staticClass: "in-one a-fadeinT"
  }, [_vm._v("欢迎使用Zenlayer DNS")]), _c("p", {
    staticClass: "in-two a-fadeinT"
  }, [_vm._v("您需要初始化您的数据库并且填充初始数据")]), _c("div", {
    staticClass: "form-card in-three a-fadeinB"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "数据库类型"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.sqlType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sqlType", $$v);
      },
      expression: "form.sqlType"
    }
  }, [_c("el-option", {
    key: "mysql",
    attrs: {
      label: "mysql(目前只支持mysql)",
      value: "mysql"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "host"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入数据库链接"
    },
    model: {
      value: _vm.form.host,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "host", $$v);
      },
      expression: "form.host"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "port"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入数据库端口"
    },
    model: {
      value: _vm.form.port,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "port", $$v);
      },
      expression: "form.port"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "userName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入数据库用户名"
    },
    model: {
      value: _vm.form.userName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userName", $$v);
      },
      expression: "form.userName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "password"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入数据库密码（没有则为空）"
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "dbName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入数据库名称"
    },
    model: {
      value: _vm.form.dbName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dbName", $$v);
      },
      expression: "form.dbName"
    }
  })], 1), _c("el-form-item", [_c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v("立即初始化")])], 1)])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };